import * as icon from '../assets/Icons'
import conferenceHelper from '../helpers/ConferenceHelper';
import scheduleHelper from '../helpers/ScheduleHelper';
import SecurityHelper from '../helpers/SecurityHelper';

export default function exhibitor() { 
  return [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: icon.dashboard,
      alwaysIcon: true
    },
    {
      name: 'Conference',
      icon: icon.conference,
      tag: "conference",
      visible: () => !conferenceHelper.isConferenceExpired(),
      children: [
        {
          name: 'Presenters',
          url: '/presenters',
          icon: icon.presenter,
        },
        {
          name: 'Sponsors',
          url: '/sponsors',
          icon: icon.sponsorship,
          visible: SecurityHelper.getSettings().SponsorsEnabled
        },
        {
          name: 'Exhibitors',
          url: '/exhibitors',
          icon: icon.company
        },
        {
          name: 'Virtual Exhibit Hall',
          url: '/exhibitors/virtual',
          icon: icon.virtual_exhibit,
          visible: SecurityHelper.getSettings().VirtualExhibitEnabled
        },
        {
          name: 'Schedule at a Glance',
          url: '/schedule',
          icon: icon.calendar_week,
          visible: scheduleHelper.publicationStatus,
        },
        {
          name: 'Announcements',
          url: '/announcements',
          icon: icon.announcement,
        },
        {
          name: 'Maps',
          url: '/maps',
          icon: icon.map,
        }, 
        {
          name: 'Info',
          url: '/info',
          icon: icon.conference
        },
      ]
    },
    {
      name: 'Leads',
      url: '/leads',
      icon: icon.users, 
      visible: () => SecurityHelper.canExecuteAction("lead.list") && !conferenceHelper.isConferenceExpired(),
      alwaysIcon: true
    },
    {
      name: 'Leads Analytics',
      url: '/analytics/leads',
      icon: icon.analytics,
      visible: "lead.reportquery",
      alwaysIcon: true
    }, 
    {
      name: 'Appointments',
      url: '/appointments',
      icon: icon.appointment,
      alwaysIcon: true
    },
    {
      name: 'Messages',
      url: '/messages',
      icon: icon.message,
      alwaysIcon: true
    },
    {
      name: 'Interested Attendees',
      url: '/analytics/interested',
      icon: icon.attendance,
      visible: SecurityHelper.isExhibitorAdmin() && (SecurityHelper.canExecuteAction("lead.reportquery") && SecurityHelper.getSettings().InterestedAttendeesReportEnabled),
      alwaysIcon: true
    },
    {
      name: 'Prizes',
      url: '/leads/rewards',
      icon: icon.awards,
      visible: SecurityHelper.isExhibitorAdmin() && (SecurityHelper.canExecuteAction("lead.topattendees") || SecurityHelper.canExecuteAction("lead.randomdrawing")) && !conferenceHelper.isConferenceExpired(),
      alwaysIcon: true
    },
    {
      name: 'Send Leads an Email',
      url: '/leads/emailbroadcast',
      icon: icon.email,
      visible: SecurityHelper.canExecuteAction("broadcast.emailleads") && !SecurityHelper.canExecuteAction("broadcast.email"),
      alwaysIcon: true
    },
    {
      name: 'Export Leads',
      url: '/export/download',
      icon: icon.export_data,
      visible: ["export.leads"],
      alwaysIcon: true
    },
    {
      name: 'Help Desk',
      icon: icon.help,
      tag: 'support',
      children: [
        {
          name: 'My Tickets',
          url: '/support/tickets',
          icon: icon.ticket,
        } 
      ]
    },
    {
      name: 'Exhibitor Settings',
      icon: icon.user,
      tag: "Exhibitor Settings", 
      visible: () => !conferenceHelper.isConferenceExpired(),
      children: [
        {
          name: 'My Company',
          url: '/companies/mine',
          icon: icon.company,
          visible: SecurityHelper.isExhibitorAdmin() && ( SecurityHelper.canExecuteAction("company.savemine") && !SecurityHelper.canExecuteAction("report.query"))
        },
        {
          name: 'My Custom Qualifiers',
          url: '/leads/form',
          icon: icon.edit,
          visible: SecurityHelper.isExhibitorAdmin() && ( SecurityHelper.canExecuteAction("lead.saveform") && !SecurityHelper.canExecuteAction("report.query"))
        },
        {
          name: 'My Reps',
          url: '/users/exhibitor representative',
          icon: icon.representative,
          visible: SecurityHelper.isExhibitorAdmin() &&  SecurityHelper.canExecuteAction("exhibitorrep.list")
        },
        {
          name: 'My Ad',
          url: '/ads',
          icon: icon.ad,
          visible: () => SecurityHelper.isExhibitorAdmin()
        },
        {
          name: 'My Profile',
          url: '/profile',
          icon: icon.user,
        }
      ]
    },
  ]
}